import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button, Icon, Modal, Checkbox, Spin } from 'antd';
import Nestable from 'react-nestable';

import { actions } from './formation-paths.redux';
import './formation-path-detail.page.css';

class FormationPathDetailPage extends PureComponent {
  state = {
    path: null,
    courses: [],
    coursesToAdd: [],
    modalVisible: false
  };

  componentDidMount() {
    const pathId = this.props.match.params.id;
    const path = this.props.formationPaths.find(p => p.id === parseInt(pathId, 10));
    this.setState({ path });
    this.props.layout.changeTitle(path.name);
    this.props.get(pathId);
  }

  canEdit = () => {
    return this.props.auth.user.role === 'admin';
  }

  componentWillReceiveProps(nextProps) {
    if(this.props.saving && !nextProps.saving && !nextProps.error) {
      this.props.history.goBack();
    }

    if(nextProps.path && nextProps.path.courses) {
      var courses = this.unflattenCourses(nextProps.path.courses);
      courses = this.setIndexes(courses, 0);
      this.setState({ courses });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async(err, values) => {
      if (!err) {
        this.props.create(values.name, this.flattenCourses(this.state.courses, null));
      }
    });
  }

  unflattenCourses = (courses, parentId = null) => {
    var levelCourses = courses.filter(c => c.parent_course_id === parentId);
    
    for(var course of levelCourses) {
      course.children = this.unflattenCourses(courses, course.id);
    }

    return levelCourses;
  }

  flattenCourses = (courses, parent) => {
    var result = [];
    var order = 1;
    for(var course of courses) {
      result.push({ id: course.id,  order, parent_course_id: parent });
      if(course.children) result = result.concat(this.flattenCourses(course.children, course.id));
      order++;
    }

    return result;
  }

  renderItem = ({ item }) => {
    return <div className="drag-item">
      <span className="badge">{item.index}</span>
      {item.name}
      { this.canEdit() && <Icon type="close" className="del-icon" onClick={() => this.removeCourse(item) } /> }
    </div>
  };

  removeCourse = (course) => {
    var courses = [ ...this.state.courses];
    this.removeCourseFromArray(courses, course);
    
    this.setState({ courses: this.setIndexes(courses, 0) });
    this.props.removeCourse(this.state.path.id, course.id);
  }

  removeCourseFromArray = (items, course) => {
    var idx = items.findIndex(c => c.id === course.id);
    if(idx >= 0) {
      items.splice(idx, 1);
    }
    else {
      items.forEach(i => {
        if(i.children) this.removeCourseFromArray(i.children, course);
      });
    }
  }

  onChange = (items, item) => {
    var newItems = this.setIndexes(items, 0);
    this.setState({ courses: newItems  });
    this.props.upsertCourses(this.state.path.id, this.flattenCourses(newItems));
  }

  setIndexes = (items, index) => {
    if(!items) return items;
    let prefix = index > 0 ? index + '.' : '';

    return items.map((item, idx) => {
      item.index = prefix + (idx+1);
      item.children = this.setIndexes(item.children, item.index);
      return item;
    });
  }

  addCourses = () => {
    var { courses, coursesToAdd } = this.state;
    var newCourses = this.setIndexes(courses.concat(coursesToAdd), 0);
    this.setState({ courses: newCourses, coursesToAdd: [] });
    this.hideModal();
    this.props.upsertCourses(this.state.path.id, this.flattenCourses(newCourses));
  }

  hideModal = () => {
    this.setState({ modalVisible: false });
  }

  showModal = () => {
    this.setState({ modalVisible: true });
  }

  isCourseAdded = (course) => {
    return this.findCourse(this.state.courses, course);
  }

  findCourse = (items, course) => {
    if(!items) return false;
    return items.some(c => c.id === course.id) || items.reduce((prev, curr) => {
      return prev || this.findCourse(curr.children, course);
    }, false);
  }

  selectCourse = (checked, course) => {
    var coursesToAdd  = [ ...this.state.coursesToAdd]; 
    if(checked) {
      coursesToAdd = [...coursesToAdd, course];
      
    }
    else {
      coursesToAdd = coursesToAdd
        .splice(coursesToAdd.findIndex(c => c.id === course.id), 1);
    }

    this.setState({ coursesToAdd });
  }

  render() {
    return <div className="content-internal">
      <div>
      { this.canEdit() && <div className="add-course-btn">
        <Button className="" type="ghost" onClick={this.showModal}><Icon type="plus" />Agregar curso</Button>
      </div> }

      <Spin spinning={this.props.fetching_path}>
        <Nestable
            items={this.state.courses}
            renderItem={this.renderItem}
            onChange={this.onChange}
            confirmChange={() => this.canEdit()}
        />
      </Spin>

      <Modal
        title="Seleccione los cursos"
        visible={this.state.modalVisible}
        onOk={this.addCourses}
        onCancel={this.hideModal}
        okText="Agregar"
        cancelText="Cancelar"
      >
        <Spin spinning={this.props.courses.fetching}>
          { this.props.courses.courses.map(c => {
            return <Checkbox key={c.id} onChange={(e) => this.selectCourse(e.target.checked, c)} checked={this.isCourseAdded(c) || this.state.coursesToAdd.some(sc => sc.id === c.id)} disabled={this.isCourseAdded(c)}>{c.name}</Checkbox>
          })}
        </Spin>
      </Modal>
      </div>
    </div>
  }
}

function mapStateToProps(state) {
  return   { ...state.formationPaths, courses: state.courses, auth: state.auth };
}

function mapDispatchToProps(dispatch) {
  return {
    create: (name, courses) => dispatch(actions.create(name, courses)),
    get: (pathId) => dispatch(actions.get(pathId)),
    upsertCourses: (pathId, courses) => dispatch(actions.upsert(pathId, courses)),
    removeCourse: (pathId, courseId) => dispatch(actions.removecourse(pathId, courseId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormationPathDetailPage);
