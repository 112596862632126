import BaseService from './baseService';

export default class FormationPathsService extends BaseService
{
  static async list(limit, skip) {
    return await super.request(`/paths?limit=${limit}&skip=${skip}`);
  }

  static async get(pathId) {
    return await super.request(`/paths/${pathId}`);
  }

  static async create(name, courses) {
    return await super.request(`/paths`, 'POST', { name, courses });
  }

  static async upsert(pathId, courses) {
    return await super.request(`/paths/${pathId}`, 'PUT', { courses });
  }

  static async removeCourse(pathId, courseId) {
    return await super.request(`/paths/${pathId}/courses/${courseId}`, 'DELETE');
  }
}
