import BaseService from './baseService';

export default class UsersService extends BaseService {
  static async list(query, limit, skip) {
    return await super.request(
      `/users?limit=${limit}&skip=${skip}&q=${
        query ? encodeURIComponent(query) : ''
      }`
    );
  }

  static async find(userId) {
    return await super.request(`/users/${userId}`);
  }

  static async export(ids) {
    return await super.request(`/users/export?ids=${JSON.stringify(ids)}`);
  }

  static async validateImport(data) {
    return await super.request(`/users/import/validate`, 'POST', {
      file: data,
    });
  }

  static async validateImportStatus(jobId) {
    return await super.request(
      `/users/import/validate/status?jobId=${jobId || ''}`,
      'POST'
    );
  }

  static async import() {
    return await super.request(`/users/import`, 'POST');
  }

  static async importStatus(jobId) {
    return await super.request(
      `/users/import/status?jobId=${jobId || ''}`,
      'POST'
    );
  }

  static async bulkEnableDisable(data, action) {
    return await super.request(`/users/bulk/${action}`, 'PUT', { file: data });
  }

  static async search(query) {
    return await super.request(`/users?q=${encodeURIComponent(query)}`);
  }

  static async create(
    username,
    email,
    first_name,
    last_name,
    mobile,
    avatar,
    area_id,
    position_id,
    role,
    password,
    identification_number,
    location,
    manager_id,
    extended_field1,
    extended_field2,
    extended_field3,
    extended_field4,
    extended_field5,
    extended_field6,
    extended_field7,
    organizational_units
  ) {
    return await super.request(`/users`, 'POST', {
      username,
      email,
      first_name,
      last_name,
      mobile: (mobile || '').trim(),
      avatar,
      area_id,
      position_id,
      role,
      password,
      identification_number,
      location,
      manager_id,
      extended_field1,
      extended_field2,
      extended_field3,
      extended_field4,
      extended_field5,
      extended_field6,
      extended_field7,
      organizational_units,
    });
  }

  static async update(
    id,
    email,
    first_name,
    last_name,
    mobile,
    avatar,
    area_id,
    position_id,
    role,
    identification_number,
    location,
    manager_id,
    extended_field1,
    extended_field2,
    extended_field3,
    extended_field4,
    extended_field5,
    extended_field6,
    extended_field7,
    organizational_units
  ) {
    return await super.request(`/users/${id}`, 'PUT', {
      email,
      first_name,
      last_name,
      mobile: (mobile || '').trim(),
      avatar,
      area_id,
      position_id,
      role,
      identification_number,
      location,
      manager_id,
      extended_field1,
      extended_field2,
      extended_field3,
      extended_field4,
      extended_field5,
      extended_field6,
      extended_field7,
      organizational_units,
    });
  }

  static async emailExists(email) {
    const resp = await super.request(`/users/email?q=${email}`);
    return resp.exists;
  }

  static async mobileExists(mobile) {
    const resp = await super.request(`/users/mobile?q=${mobile}`);
    return resp.exists;
  }

  static async getAreas() {
    return await super.request(`/users/areas`);
  }

  static async getPositions() {
    return await super.request(`/users/positions`);
  }

  static async getLocations() {
    return await super.request(`/users/locations`);
  }

  static async delete(ids) {
    return await super.request(`/positions/${JSON.stringify(ids)}`, 'DELETE');
  }

  static async changePassword(id, password) {
    return await super.request(`/users/account/change_password`, 'PUT', {
      id,
      password,
    });
  }

  static async enableDisable(ids, action) {
    return await super.request(
      `/users/${JSON.stringify(ids)}/${action}`,
      'PUT'
    );
  }

  static async getUserCourses(userId) {
    return await super.request(`/users/${userId}/courses`);
  }

  static async getWhatsappUserCourses(userId) {
    return await super.request(`/users/${userId}/whatsappcourses`);
  }

  static async getAuditInfo(userId) {
    return await super.request(`/users/${userId}/audit`);
  }

  static async getBadges(userId) {
    return await super.request(`/users/${userId}/badges`);
  }

  static async revalidate(userId, courseId, percentage, date, comments) {
    return await super.request(`/responses/revalidate`, 'POST', {
      course: courseId,
      user: userId,
      percentage,
      date,
      comments,
    });
  }
}
