import BaseService from "./baseService";

export default class ExternalCoursesService extends BaseService {
  static async list(limit, skip) {
    return await super.request(`/external-courses?limit=${limit}&skip=${skip}`);
  }

  static async availableUsers(courseId, q, f) {
    return await super.request(`/external-courses/${courseId}/users/available?q=${q || ''}&f=${f || ''}`);
  }

  static async disabledUsers(courseId, q) {
    return await super.request(`/external-courses/${courseId}/users/disabled?q=${q || ''}`);
  }

  static async assignUsers(courseId, userIds) {
    return await super.request(`/external-courses/${courseId}/users/${JSON.stringify(userIds)}`, 'POST');
  }

  static async disableUsers(courseId, userIds) {
    return await super.request(`/external-courses/${courseId}/users/disable/${JSON.stringify(userIds)}`, 'POST');
  }

  static async validateImport(courseId, data) {
    return await super.request(`/external-courses/${courseId}/users/import/validate`, 'POST', { file: data });
  }

  static async validateImportStatus(courseId, jobId) {
    return await super.request(`/external-courses/${courseId}/users/import/validate/status?jobId=${jobId || ''}`, 'POST');
  }

  static async import(courseId) {
    return await super.request(`/external-courses/${courseId}/users/import`, 'POST');
  }

  static async importStatus(courseId, jobId) {
    return await super.request(`/external-courses/${courseId}/users/import/status?jobId=${jobId || ''}`, 'POST');
  }

  static async getCourse(courseId) {
    return await super.request(`/external-courses/${courseId}`, 'GET');
  }

  static async getUsers(courseId) {
    return await super.request(`/external-courses/${courseId}/users`, 'GET');
  }

  static async export(courseId) {
    return await super.request(`/external-courses/${courseId}/users/export`);
  }

  static async createCourse(course) {
    return await super.request(`/external-courses`, 'POST', course);
  }

  static async updateCourse(courseId, updates) {
    return await super.request(`/external-courses/${courseId}`, 'PUT', updates);
  }

  static async updateUserCourse(userId, courseId, updates) {
    return await super.request(`/external-courses/${courseId}/users/${userId}`, 'PUT', updates);
  }

  static async deleteCourse(courseId) {
    return await super.request(`/external-courses/${courseId}`, 'DELETE');
  }

  static async getCategories() {
    return await super.request(`/external-courses/filters/categories`);
  }

  static async getCompetencies() {
    return await super.request(`/external-courses/filters/competencies`);
  }

  static async getAssignedSkills(courseId) {
    return await super.request(
      `/external-courses/${courseId}/skills`
    );
  }

  static async assignSkills(courseId, skills) {
    return await super.request(
      `/external-courses/${courseId}/skills/${JSON.stringify(skills)}`,
      'POST'
    );
  }

}